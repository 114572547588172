<script setup lang="ts">
import { onMounted } from "vue";
import { setPageTitle } from "@/utils/pageTitle";
// @ts-ignore
import useUsers from "@/hooks/useUsers";
import gravatarUrl from "gravatar-url";
// @ts-ignore
import { useRoute } from "vue-router/auto";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { useToast } from "vue-toastification";
import UserPermissionsCard from "@/components/user/UserPermissionsCard.vue";
import UserPinCard from "@/components/user/UserPinCard.vue";
import UserPasswordCard from "@/components/user/UserPasswordCard.vue";
import UserUpdateProfileCard from "@/components/user/UserUpdateProfileCard.vue";
import LocalTimezoneCard from "@/components/user/LocalTimezoneCard.vue";
import DefaultWarehouseCard from "@/components/user/DefaultWarehouseCard.vue";

const { user, fetchUser } = useUsers();
const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const toast = useToast();

onMounted(async () => {
  if (
    (userStore.user && userStore.user.id == route.params.id) ||
    userStore.hasPermissionTo("update_user_account")
  ) {
    await fetchUser(route.params.id).then((response: any) => {
      setPageTitle(`Editing ${response?.name}'s User Profile`);
    });
  } else {
    toast.error("You do not have permission to edit this user");
    router.push(`/users`);
  }
});
</script>

<template>
  <div class="d-flex align-center justify-space-between px-4" v-if="user">
    <div class="d-flex align-center">
      <v-avatar class="d-flex align-center mt-2"
        ><v-img
          alt="Avatar"
          :src="gravatarUrl(user.email, { size: 100, default: 'retro' })"
        ></v-img
      ></v-avatar>
      <div>
        <v-card-title class="d-flex align-center pb-0">
          {{ user.name }}
          <span class="text-caption ml-2 text-medium-emphasis">{{ user.email }}</span>
        </v-card-title>
        <v-card-subtitle class="mt-0"
          ><span><strong>Tenant: </strong>{{ user.tenant?.name }}</span>
          <span v-if="user.client" class="ml-2"
            ><strong>Client: </strong>{{ user.client.name }}</span
          ></v-card-subtitle
        >
      </div>
    </div>
    <v-btn color="primary" variant="flat" @click="router.push(`/users`)">Back</v-btn>
  </div>

  <UserUpdateProfileCard />

  <!-- UPDATE PIN CARD -->
  <UserPinCard />

  <!-- UPDATE PASSWORD CARD -->
  <UserPasswordCard />

  <!-- UPDATE LOCAL TIMEZONE -->
  <LocalTimezoneCard />

  <DefaultWarehouseCard />

  <!-- UPDATE PIN CARD -->
  <UserPermissionsCard v-if="userStore.hasPermissionTo('update_user_permissions')" />
</template>
