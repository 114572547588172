<script setup lang="ts">
import { onMounted, ref, Ref } from "vue";
// @ts-ignore
import useUsers from "@/hooks/useUsers";
// @ts-ignore
import { useRoute } from "vue-router/auto";

const { fetchUser, updatePin } = useUsers();
const route = useRoute();
const userData = ref({
  name: null,
  email: null,
  title: null,
  api_token: null,
  active: null,
  phone: "",
  pin: null,
  pin_confirmation: null,
  password: null,
  password_confirmation: null,
});

onMounted(async () => {
  await fetchUser(route.params.id).then((response: any) => {
    userData.value = response;
  });
});

const handleUpdatePin = async () => {
  let params = {
    pin: userData.value.pin,
    pin_confirmation: userData.value.pin_confirmation,
  };
  await updatePin(route.params.id, params);
  fetchUser(route.params.id).then((response: any) => {
    userData.value = response;
  });
};
</script>

<template>
  <!-- UPDATE PIN CARD -->
  <v-card flat class="mx-4 mt-8">
    <v-card-title>Update Pin</v-card-title>

    <v-card-text>
      <v-text-field
        v-model="userData.pin"
        label="Pin"
        type="password"
        name="pin"
        :rules="[(v) => (v != null && v.length == 6) || 'Pin must be 6 digits']"
      ></v-text-field>
      <v-text-field
        v-model="userData.pin_confirmation"
        :rules="[(v) => (v != null && v.length == 6) || 'Pin must be 6 digits']"
        label="Confirm Pin"
        type="password"
        name="pin_confirmation"
      ></v-text-field>
    </v-card-text>

    <v-card-actions class="flex justify-end">
      <v-btn color="primary" variant="flat" @click="handleUpdatePin">Update Pin</v-btn>
    </v-card-actions>
  </v-card>
</template>
