<script setup lang="ts">
import { onMounted, ref } from "vue";
// @ts-ignore
import useUsers from "@/hooks/useUsers";
// @ts-ignore
import { useRoute } from "vue-router/auto";
import useTimezones from "@/hooks/useTimezone";

const { fetchUser, updateUser } = useUsers();
const {timezones, fetchTimezones} = useTimezones();

const route = useRoute();
const userData = ref({
  local_timezone: null
});

onMounted(async () => {
  await fetchTimezones(); // Fetch available timezones on mount
  await fetchUser(route.params.id).then((response: any) => {
    userData.value = response;
  });
});

const handleUpdateLocalTimezone = async () => {
  let params = {
    local_timezone: userData.value.local_timezone,
  };
  await updateUser(route.params.id, params);
  fetchUser(route.params.id).then((response: any) => {
    userData.value = response;
  });
};
</script>

<template>
  <!-- UPDATE TIMEZONE CARD -->
  <v-card flat class="mx-4 mt-8">
    <v-card-title>Update Local Timezone</v-card-title>

    <v-card-text>
      <v-autocomplete
        v-model="userData.local_timezone"
        label="Local Timezone"
        name="local_timezone"
        :items = "timezones"
      ></v-autocomplete>
    </v-card-text>

    <v-card-actions class="flex justify-end">
      <v-btn color="primary" variant="flat" @click="handleUpdateLocalTimezone">Update Timezone</v-btn>
    </v-card-actions>
  </v-card>
</template>
