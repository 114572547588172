<script setup lang="ts">
import { onMounted, ref } from "vue";
// @ts-ignore
import useUsers from "@/hooks/useUsers";
// @ts-ignore
import { useRoute } from "vue-router/auto";

const { fetchUser, updateUser } = useUsers();
const route = useRoute();
const userData = ref({
  name: null,
  email: null,
  title: null,
  api_token: null,
  active: null,
  phone: "",
  pin: null,
  v3_login_timeout_ms: null,
  enforce_v3_login_timeout: null,
  pin_confirmation: null,
  password: null,
  password_confirmation: null,
});

onMounted(async () => {
  await fetchUser(route.params.id).then((response: any) => {
    userData.value = response;
  });
});

const handleUpdateUser = async () => {
  await updateUser(route.params.id, userData.value);
  fetchUser(route.params.id).then((response: any) => {
    userData.value = response;
  });
};
</script>

<template>
  <v-card flat class="mx-4 mt-8">
    <v-card-title>Edit Profile</v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field v-model="userData.name" label="Name"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="userData.email" label="E-mail Address"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-phone-input v-model="userData.phone" name="phone" />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="userData.title" label="Title"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-select
            v-model="userData.active"
            :items="[
              { text: 'Active', value: true },
              { text: 'Disabled', value: false },
            ]"
            label="Status"
            item-title="text"
            item-value="value"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" class="d-flex">
          <v-switch
            v-model="userData.enforce_v3_login_timeout"
            color="primary"
            label="Enforce Login Timeout"
          ></v-switch>
          <v-text-field
            v-if="userData.enforce_v3_login_timeout"
            v-model="userData.v3_login_timeout_ms"
            class="ml-4"
            label="Timeout (ms)"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="flex justify-end">
      <v-btn color="primary" variant="flat" @click="handleUpdateUser"
        >Update Profile</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
