<script setup lang="ts">
import { onMounted, ref } from "vue";
// @ts-ignore
import useUsers from "@/hooks/useUsers";
// @ts-ignore
import { useRoute } from "vue-router/auto";
import useWarehouses from '@/hooks/useWarehouses';

const { fetchUser, updateUser } = useUsers();
const {warehouses, fetchWarehouses} = useWarehouses();

const route = useRoute();
const userData = ref({
    default_warehouse_id: null
});

onMounted(async () => {
  await fetchWarehouses();
  await fetchUser(route.params.id).then((response: any) => {
    userData.value = response;
  });
});

const handleUpdateDefaultWarehouse = async () => {
  let params = {
    default_warehouse_id: userData.value.default_warehouse_id,
  };
  await updateUser(route.params.id, params);
  fetchUser(route.params.id).then((response: any) => {
    userData.value = response;
  });
};
</script>

<template>
  <v-card flat class="mx-4 mt-8">
    <v-card-title>Update Default Warehouse.</v-card-title>

    <v-card-text>
      <v-autocomplete
        v-model="userData.default_warehouse_id"
        label="Default Warehouse Id"
        name="default_warehouse_id"
        :items = "warehouses?.data"
        item-title="name"
        item-value="id"
      ></v-autocomplete>
    </v-card-text>

    <v-card-actions class="flex justify-end">
      <v-btn color="primary" variant="flat" @click="handleUpdateDefaultWarehouse">Update Default Warehouse</v-btn>
    </v-card-actions>
  </v-card>
</template>
