<script setup lang="ts">
import { onMounted, ref, Ref } from "vue";
// @ts-ignore
import useUsers from "@/hooks/useUsers";
// @ts-ignore
import { useRoute } from "vue-router/auto";
import useAuth from "@/hooks/useAuth";
import { userStore } from "@/stores";

const { fetchUser, updatePassword } = useUsers();
const { signOut } = useAuth();
const route = useRoute();
const userData = ref({
  name: null,
  email: null,
  title: null,
  api_token: null,
  active: null,
  phone: "",
  pin: null,
  pin_confirmation: null,
  password: null,
  password_confirmation: null,
});

onMounted(async () => {
  await fetchUser(route.params.id).then((response: any) => {
    userData.value = response;
  });
});

const handleUpdatePassword = async () => {
  let params = {
    password: userData.value.password,
    password_confirmation: userData.value.password_confirmation,
  };
  await updatePassword(route.params.id, params).then((res: any) => {
    if (res.status == 200 && userStore.user?.id == route.params.id) {
      signOut();
    }
  });
};
</script>

<template>
  <v-card flat class="mx-4 mt-8">
    <v-card-title>Update Password</v-card-title>
    <v-card-text>
      <v-text-field
        v-model="userData.password"
        label="Password"
        type="password"
        name="password"
        :rules="[
          (v) => (v != null && v.length > 6) || 'Password must be more than 6 characters',
        ]"
      ></v-text-field>
      <v-text-field
        v-model="userData.password_confirmation"
        :rules="[
          (v) => (v != null && v.length > 6) || 'Password must be more than 6 characters',
        ]"
        label="Confirm Password"
        type="password"
        name="password_confirmation"
      ></v-text-field>
    </v-card-text>

    <v-card-actions class="flex justify-end">
      <v-btn color="primary" variant="flat" @click="handleUpdatePassword"
        >Update Password</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
